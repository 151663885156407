.renewal {
  .module {@include padbox(1em);
    @include desk {padding: 1em 0;}
  }

  width: 100%;
  &--choose {
    color: #d52b1e; background-color: white;
    .roboto-bold { font-family: $font-bold; }

    .red-color { color: #d52b1e; }

    .black-color { color: $black; }

    .text-bold { font-weight: 700; }

    .form-cont,
    .ui--modal-notification.active section {
      background: white;
      padding: 4%;
      border-radius: 14px;
      box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.29);
      -webkit-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);
      -moz-box-shadow: 9px 15px 14px -1px rgba(0,0,0,0.16);
    }

    .form-bottom-text { color: #888888; font-weight: 300; }

    .form-control {
      border: 1px solid #DEDEDE;
      &.error { border-color: #d52b1e; }
    }

    .btn-close {
      position: absolute;
      right: -12px;
      top: -13px;
      background: white;
      border-radius: 24px;
      width: 32px;
      height: 32px;
    }
  }

  &--content {
    width: 100%;
    @include padbox(.5em);
    margin-top: 1em;
    @include tablet {margin-top: 3em;}
    @include desk {@include padbox(1em);}
    section {width: 100%; @include module;
      > header {width: 100%; @include padbox(0 1em 1em 1em);
        h1 {font-size: 18px; font-weight: 900;
        @include desk {
          font-size: 2em; font-weight: 300;
        }
      }
        @include desk {padding: 0 0 1em 0;}
      }
      @include flexbox; @include flow-rw; @include just-between; @include items-stretch;
      @include desk {padding: 0;}
      section {
        width: 100%;
        @include padbox(.75em);
        @include desk {
          @include padbox(1.35em);
        }
        position: relative;
        margin-bottom: 1em;
        box-shadow: 3px 3px 10px #00000033;
        border-radius: 10px;
        @include tablet {width: 48.5%;
          &:first-of-type {
            margin-right: 0.75%;
          }
          &:last-of-type {
            margin-left: 0.75%;
          }
        }
      }
      .grid-container {
        width: 100%;
        @include module;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-bottom: 8em;
        @include tablet {
          margin-bottom: 5em;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        grid-auto-rows: auto;
        grid-gap: 1.5em;
        .grid-item {
          height: 100%;
          section {
            width: 100%;
            height: 100%;
            @include padbox(.75em);
            @include desk {
              padding: 1.35em 1em;
            }
            position: relative;
            box-shadow: 3px 3px 10px #00000033;
            border-radius: 10px;
            margin: 0;
            &:first-of-type {
              margin-right: 0 !important;
              margin: 0 !important;
            }
            &:last-of-type {
              margin: 0 !important;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
    .ver-mas {
      width: 70%;
      margin: auto;
      section {
        width: 100%;
        // height: 100%;
        position: relative;
        box-shadow: 3px 3px 10px #00000033;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        &:first-of-type {
          margin-right: 0 !important;
          margin: 0 !important;
        }
        &:last-of-type {
          margin: 0 !important;
          margin-left: 0 !important;
        }
        .plan {
          text-align: center;
          border-radius: 10px;
          border-bottom: 5px solid #2999a9;
          padding: 2em 1em;
          @include tablet {
            padding: 4em 2em;
          }
          width: 100%;
          margin: auto;
          height: auto;
          button {@include btn-go; width: 100%;
            @include tablet {margin-bottom: 0;}
            span::before {display: none !important;}
            padding: .4em 1em !important;
            background-color: #2999A9;
            border-color: #2999A9;
          }
        }
      }
    }
    .footer {
      margin-top: 4em;
      width: 92%;
      @include tablet {
        width: 100%;
      }
      text-align: center;
      position: absolute;
      bottom: 2em;
      @include items-center;
      h5 {
        font-size: 16px;
        a {font-size: 1em; font-weight: 500; color: $red;}
        margin: 0 auto;
      }
    }
  }

  &--contract-content {
    width: 100%;
    @include padbox(1em);
    margin-top: 25%;
    @include desk {padding: 1em 0;
      margin-top: 7%;
    }
    section {width: 100%; @include module;
      // @include flexbox; @include flow-rw; @include just-between; @include items-stretch;
      @include desk {padding: 0;}
      section {
        width: 100%;
        @include padbox(.75em);
        @include desk {
          @include padbox(1.35em);
        }
        position: relative;
        margin-bottom: 1em;
        box-shadow: 3px 3px 10px #00000033;
        border-radius: 10px;
        @include tablet {
          width: 78%;
        }
        @include desk {
          width: 60%;
        }
      }
    }
  }

  &--contract-info {
    width: 100%;
    margin-left: auto;
    margin-bottom: 0;
    .header-contract {
      height: auto;
      width: auto;
      position: absolute;
      left: calc(50% - 75px);
      top: -75px;
      background-color: transparent !important;
      // @include flexbox;
      picture {
        width: auto;
        img {
          width: 150px;
        }
      }
    }
    .container {
      text-align: center;
      margin-top: 4em;
      @include tablet {
        margin-top: 3em;
      }
      width: 100%;
      @include items-center;
      h1 {
        color: #DA291C;
        font-size: 24px;
        @include tablet {
          font-size: 35px;
        }
        font-weight: 800;
      }
      h3 {
        margin: 0 auto;
        width: 79%;
        color: #333333;
        font-size: 14px;
        @include tablet {
          font-size: 18px;
          margin: 0;
          width: 100%;
        }
        margin-top: 1em;
        font-weight: 800;
      }
      p {
        margin: 0 auto;
        margin-top: 0.65em;
        font-size: 12px;
        width: 78%;
        @include tablet {
          font-size: 1em;
          width: 70%;
        }
        color: #333333;
      }
    }
    .footer {
      p {
        margin: 0 auto;
        margin-top: 0.65em;
        font-size: 12px;
        color: #333333;
        width: 70%;
      }
    }
  }

  &--contract-continue {width: 100%;
    @include items-center;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
    button, a + a {@include btn-go; width: 75%;
      @include tablet {width: 48%; margin-bottom: 0;}
      span::before {display: none !important;}
      padding: .4em 1em !important;
    }
  }

  &--info {
    width: 100%;
    margin-left: auto;
    margin-bottom: 0;
    h2 {
      @include desk{
        font-size: 32px;
      }
      font-size: 24px;
      font-weight: 700;
      color: #0097a9;
      width: 100%;}
    p {
      width: 100%;
      margin-top: 1.1em;
      margin-bottom: -.5em;
      @include tablet {
        margin-top: 10%;
      }
      font-size: 20px;
      font-weight: 700;
      color: black;
    }
    button {@include btn-go; width: 100%;
      @include tablet { margin-bottom: 0;}
      span::before {display: none !important;}
      border-radius: 5px !important;
    }
    .container {
      margin-top: 1em;
      @include desk {
       margin-top: 3em;
       width: 100%;

      }
      width: 100%;
      @include flexbox;
      @include flow-rw;
      // @include just-between;
      // @include items-center;
      // margin: 1em 0;
      .separator {
        @include flexbox;
        picture {
            width: auto;
            // padding: 1em 0; text-align: center;
            margin-top: 2.1em; margin-left: .3em; margin-right: .3em;
            @include tablet {
              margin-left: .3em; margin-right: .3em;
            }
            img {
              width: 12px;
              @include desk {
                margin: 0 0.8em;
              }
            }
          }
      }

      .navigation {
        width: 25%;
        @include desk {
          width: 23%;
        }
        .grid-container {
          width: 100%;
          @include module;
          display: grid;
          grid-template-columns: repeat(1, minmax(0, 1fr));
          grid-auto-rows: auto;
          grid-gap: 1em;
          .grid-item {
            width: 100%;
            @include flexbox;
            @include flow-rw;
            picture {
              margin: auto 0;
              margin-right: 5px;
              width: 20px;
              @include desk {
                width: 25px;
              }
              img {
                height: auto;
                margin: 0;
              }
            }
            .info {
              //margin-left: .5em;
              .title {
                width: auto;
                margin: 0;
                @include desk {
                  font-size: 14px;
                }
                font-size: 12px;
                font-weight: 600;
                color: $red;
              }
              .description {
                width: auto;
                margin: 0;
                font-size: 15px;
                font-weight: normal;
                color: $black;
              }
            }
          }
        }
      }
      .minutes {
        width: 34%;
        @include desk {
          width: 40%;
        }
        @include tablet {
          width: 40%;
        }
        @include flexbox;
          @include flow-rw;
          .grid-container {
            width: 92%;
            @include module;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-template-rows: repeat(3,minmax(0, 3.5em));
            @include desk {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(1, minmax(0, 1fr));
              grid-template-rows: repeat(3, minmax(0, 1fr));
            }
            grid-gap: 5.5%;
            .grid-item {
              @include flexbox;
              @include flow-rw;
              text-align: left; border-bottom: 6px solid #0498AA;
              @include desk {
                text-align: center; margin-bottom: .5em;
                @include flow-rn;
              }
              box-shadow: 3px 3px 3px #00000033; border-radius: 5px; background-color: #F7F7F7;
              picture {
                width: auto;
                margin-bottom: .5em;
                img {
                  @include desk {
                    height: 30px;
                  }
                  height: 20px;
                  width: auto;
                  margin-left: .5em;
                  margin-top: .8em;
                  @include desk {
                    margin: 0 auto;
                    margin-top: .4em;
                  }
                }
              }
              p {
                width: 55%;
                margin-left: .7em !important;
                padding-left: 10px;
                text-align: start;
                margin-right: 0.4px;
                @include desk {
                  width: auto;
                  margin: 0;
                  margin-left: 0 !important;
                  font-size: 15px;
                  align-self: center;
                }
                margin: auto;
                font-size: 10px;
                font-weight: normal;
                color: $black;
              }
            }
          }
      }
      .social {
        width: auto;
        text-align: center;
        @include desk{
          margin: auto;
        }
        p {
          width: auto;
          margin: 0;
          font-size: 0.7em;
          font-weight: 600;
          color: $black;
        }
        .grid-container {
          width: 100%;
          @include module;
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-auto-rows: 2em;
          grid-gap: 0.45em;
          margin-bottom: .5em;
          .grid-item {
            picture {
              width: auto;
              img {
                height: auto;
                width: 20px;
                @include desk{
                  width: 30px;
                }
                margin: 0;
              }
            }
          }
        }
      }
    }
    .periods {
      width: 100%;
      margin-top: calc(0.5em + 11px);
      margin-bottom: 10px;
      .btn-check {
        display: none;
      }
      .grid-container {
        width: 100%;
        @include module;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        @include tablet {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include desk {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        grid-gap: .5em;
        .grid-item {
          width: 100%;
          .plan-btn {
            @include btn-back;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-origin: padding-box;
            background-clip: padding-box;
            border-radius: 2px;
            // padding: .5em 2em !important;
            width: 100%;
            background-color: white;
            color: $gray;
            border-color: $gray;
            &.checked {
              background-color: #2999A9;
              color: white;
            }
            font-size: 14px;
            // margin: 0.8em auto;
            @include tablet {
              font-size: 13px;
              font-weight: normal;
            }
          }
          .btn-check:checked+.plan-btn {
            background-color: #2999A9;
            border-color: #2999A9;
            color: white;
          }
        }
      }
    }
  }

  &--form {
    width: 100%;
    margin-bottom: 0;
      p {
        font-size: 13px;
        @include desk {
          width: 100%;
        }
        font-weight: 400;
        padding-bottom: 6px;
        span {
          color: $red;
        }
      }
      .full {width: 100%;}
      .full-checkbox {
        margin-bottom: -6em;
        margin-top: -1.5em;
        width: 100%;
        @include flexbox; @include flow-rw; @include just-between; @include items-stretch;
        margin-bottom: -2em;
      }
      .fileName {
        margin-top: 0.2em;
        color: #BBBBBB;
        font-size: 0.5em;
        margin-bottom: .2em;
      }
      .dropzone{
        width: 100%;
        padding: .6em;
        box-sizing: border-box;
        text-align: center;
        color: #666666;
        h4 {
          display: none;
          font-size: 0.95em;
          font-weight: 400;
          margin: 0.5em 0;
          color: #0097a9;
         }
        h5 {
          margin-top: .3em;
          color: #666666;
          font-size: 8px;
        }
        p {
          margin-top: 0.2em;
          color: #999999;
          font-size: 6px;
          margin-bottom: .3em;
        }
        em {
          width: 100%;
          margin-top: .3em;
          color: silver;
          font-size: 1.2em;
          font-style: normal;
        }
        b
        {
          display: none;
          font-size: 0.95em;
          font-weight: 400;
          margin: 0.5em 0;
          color: #0097a9;
        }
        a {
          background-color: $red;
          color: white;
          border-radius: 7px;
          font-size: 0.75em;
          padding: 0.4em 1em;
          margin-bottom: .3em;
        }
        display: inline-block;
        border: 1px solid #ddd;
        &::before {content: "\f093"; font-family: $icon; padding-left: 6px; display: inline-block; @include trans; font-size: 1.2em;}
        &.active {
          display: grid;
          p{
            display: none;
          }
          &::before {content: "\f058"; font-family: $icon; padding-left: 6px; display: inline-block; @include trans; font-size: 2em;
            color: #0097a9;
          }
          h4{
            display: inline;
          }
          a { display: none;}
          h5 {display: none;}
        }
      }
      .optional p::before { display: none; }
    label {width: 100%; margin-bottom: .5em;}
    textarea { width: 100%; min-height: 4.4rem; }

    h4 {font-size: 0.8em; font-weight: 300; margin: 1em 0;}
    b {font-size: 0.8em; font-weight: 300; width: 100%; text-align: center;}
  }

  @mixin css-checkbox(
    $size: '10px',
    $valign: 'middle',
    $rounded: rounded
) {
display: none;

&:checked + i {
background: white;
border-color: $red;

&:hover:after { opacity: 1; }

&:after {
  opacity: 1;
  border-color: $red;
}
}

+ i {
box-sizing: border-box;
&:before, &:after {
   box-sizing: border-box;
}
}
+ i {
position: relative;
display: inline-block;
top: -1px;
width: #{$size};
height: #{$size};
margin: 0;
margin-right: .2em;
vertical-align: #{$valign};
border: 1px solid $red;
transition: background 200ms ease;

@if $rounded == "circle" {
  border-radius: 10em;
} @else if $rounded == "rounded" {
  border-radius: .2em;
} @else {
  border-radius: 0;
}

background: white;
font-size: #{$size};
cursor: pointer;

&:hover:after { opacity: .2; }

&:after {
  content: '';
  opacity: 0;
  transform: translateZ(1px) rotate(-45deg);
  outline: 1px solid transparent; // hack to prevent antialias atrifacts in firefox
  position: absolute;
  top: 22%;
  left: 15%;
  width: .68em;
  height: .4em;
  border: .15em solid #222;
  border-top: none;
  border-right: none;
  background: rgba(0, 0, 0, 0);
}
}
}

  &--terminos { margin: 20px 0 25px 0;
    width: 92%;
    @include tablet {
      width: 94%;
    }
    @include desk {
      width: 95%;
    }
    label {width:100%;
      margin-bottom: 0.5em;
      p {
        font-size: 9px !important;
      }
    }
    a {color: #0097a9;}
    label.error {float: left}
  }
  &--options {
    width: auto; margin: 0.5em 0 0.5em 0;
    margin-right: .1em;
    @include flexbox; @include flow-rw; @include just-between; @include items-center;
    label {width: 100%;
      p {
        font-size: 9px !important;
      }
      label.error {float:left}
      margin-bottom: 0;
      span {font-size: 1em; font-weight: 500;}
      input {
        @include css-checkbox(13px);
        margin-right: 6px; font-size: 1em;
      }
    }
  }

  &--continue {width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-center; margin-bottom: 0.5em;
    @include tablet {
      margin-bottom: 1em; margin-top: .5em;
    }
    margin-top: 1.5em;
    a {@include btn-back; width: 100%; margin-bottom: 1em;
      @include tablet {width: 48%; margin-bottom: 0;}
    }
    button, a + a {@include btn-go; width: 100%;
      @include tablet {width: 48%; margin-bottom: 0;}
      span::before {display: none !important;}
    }
  }
}
