.cross {width: 100%;


  &--module {
    &.prepago {
      @import '../base/_slick-theme';
      @include padbox(2em 40px);
      max-width: 1200px;
      .slick-prev {
        left: -20px;
        &::before {
          content: '';
        }
      }
      .slick-track {
        padding: 0;
        justify-content: center;
      }
      .slick-slide {
        justify-content: center;
        min-width: 150px;
        margin: 0 !important;
        padding: 0;
        .cross--box {
          min-width: 150px;
          padding: 0;
          margin: 1em;
        }
      }
      .slick-next {
        right: 0px;
        &::before {
          content: '';
        }
      }
    }
    width: 100%; @include padbox(0 1em 0 0); @include module; margin: 2em auto;
    h2 {font-size: 1.5em; width: 100%; text-align: left; margin-bottom: 1em;
      color: #222;
      @include phone { font-size: 1.5em; width: 100%; text-align: left; margin-bottom: 1em; color: #222; padding-left: 20px; }
    }
    .slick {
      &-slide {
        display: flex;
        width: auto;
        min-width: 220px;
        padding: 0 10px 0 0;
        margin: 10px 0;
        height: auto;
        @include phone { padding: 0 13px 0 0; margin: 0 30px 0 0!important; }
        > div { display: flex; width: auto; min-width: 220px; }
      }
      &-track { @include flexbox_i; @include flow-rn; @include just-start; @include items-stretch;
        height: auto; width: 100%;
        @include padbox(0 1.5em);
        @include desk {padding: 0;}
      }
      &-arrow {position: absolute; top: 40%; font-size: 4em; z-index: 1; color: #888888; display: none;
        @include desk {display: inline-block;}
        &:hover {color: #b3b3b3;}
      }
      &-prev {left: -40px;}
      &-next {right: -40px;}
    }
  }

  &--box {
    display: flex;
    padding: 1em;
    margin: .5em .5em .5em 1px;
    width: -webkit-fill-available;
    min-height: 25em;
    min-width: 214px;
    box-shadow: 3px 3px 10px #DCDCDC;
    border-radius: 7px;
    background-color: white;
    @include trans;
    // width: 100%; min-width: 220px; max-width: 220px;
    //min-width: 220px; max-width: 220px; min-height: 340px;

    // @include tablet {width: 24%;}
    // @include desk {width: 20%;}
    a {@include padblock(1em); width: 100%;
      display: inline-flex;
      flex-direction: column;
      height: 100%;
      h3 {display: block; font-size: 1.25em; font-weight: 600; min-height: 115px;
        color: #666666;
      }
      picture {padding: 0.5em 0; text-align: center;
        img {height: 100px; width: auto; margin: 0 auto;}
      }
      section {width: 100%; min-height: 165px; flex-grow: 1;
        b {font-size: 0.87em; font-weight: 400; padding-bottom: 0.5em; display: block;}
        p {font-size: 1.875em;
          color: $red;
          font-weight: 700;
          padding-bottom: .5em;
        }
        p.price-before, p.price-cuota {display: inline-block; font-size: 1.25rem; margin-right: 0.5rem;}
        p.price-cuota { font-size: 1.5em;
          span {
            font-size: 2rem;
          }
          span:nth-child(even) {
            font-size: 1.5rem!important;
            vertical-align: baseline!important;
          }
          span:first-child {
            display: block;
          }
          span:last-child {
            padding-left: 5px;
          }
          span:first-child, span:last-child {
            font-size: 0.87rem;
          }
        }
        &.cuota {
          p.price-before, p.price {
            color: $grey;
            display: block;
            font-size: 0.8em;
          }
          p.price {
            font-size: 1.2rem;
          }
        }
      }
      aside {width: 100%; padding-bottom: 0.5em;
        b {font-size: 0.875em; font-weight: 400; padding-bottom: 0.5em; color: $grey;}
        p {font-size: 1.25em;}
      }
      > b {@include padblock(0.6em); width: 100%; text-align: center; @include trans;
        background-color: $red; color: white; @include radius(10px);
        span {font-weight: 300;
          &::after {content: "\f105"; font-family: $icon; display: inline-block; padding-left: 7px; @include trans;}
        }
      }
    }
    &:hover {border-color: $blue;
      a > b span::after {@include transform(translateX(6px));}
    }
    a.add, b.add {color: white; background-color: $red; width: 100%;
      text-align: center; @include radius(10px);
      padding: 0;
      @include desk {width: 100%;}
      span {@include padblock(0.5em);
        &::after {content: "\f105"; font-family: $icon; display: inline-block; padding-left: 6px; vertical-align: baseline; @include trans;}
      }
      &:hover span::after {@include transform(translateX(6px));}
      &.custom { background-color: $blue; }
    }
    &.pospago {
      --clr-shadow: #DCDCDC;
      --sel-cursor: grab;
      cursor: var(--sel-cursor);
      > a {
        cursor: var(--sel-cursor);
        .link-box {
          cursor: pointer;
        }
      }
      &.hover {
        --clr-shadow: #{$red};
        --sel-cursor: grabbing;
        .link-box {
          background-color: $reddark;
          border-color: $reddark;
        }
      }
      &:hover:not(.no-hover),
      &.hover {
        box-shadow: inset -6px 0px 10px var(--clr-shadow);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.5s ease;
        @include tablet {
          box-shadow: 3px 3px 10px var(--clr-shadow);
        }
        @include desk {
          box-shadow: 2px 2px 7px var(--clr-shadow);
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }

  .btn-Mpostpago {
      font-size: 1.5rem;
      width: 29px; //23px
      height: 17px; // 17px
      display: flex;
      align-items: flex-end;
      color: #888;

  }
  .btn-modal {
   // display: inline-block;
    font-weight: 400;
    line-height: 0.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.2rem 5.75rem 1rem 6rem;
    font-size: 1rem;
    border-radius: .25rem;
    width: 100%;
    height: 1.75rem;
  }

}

.renewal-cross-text {
  display: block;
  font-size: 1.25em;
  font-weight: 600;
  min-height: 50px !important;
  color: #666666;
}